
































































































































import Vue from 'vue';
import "../../dialogs/ChangePassword.vue";
import {Backend} from "api-frontend";
import {formatDate, formatTime} from "@/helpers/format-date-time";
import {LocalDate, LocalDateTime} from "js-joda";
import {miniSchedule} from "mp-common/src/types/api/miniScheduleApi";
import {AssignmentCoordinationTemplate} from "mp-common/src/types/entities/assignment-coordination-template";
import {assignmentCoordination} from "mp-common/src/types/api/assignmentCoordinationApi";
import {FamilyWithMembers} from "mp-common/src/types/entities/family";
import {familyApi} from "mp-common/src/types/api/familyApi";
import {User} from "mp-common/src/types/entities/user";
import {users} from "mp-common/src/types/api/usersApi";

export default Vue.extend({
  name: 'CreatePlan',
  props: {
    user: {},
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      formData: {
        minMinisSunday: 4,
        minMinisSaturday: 3,
        minMinisHoliday: 5,
        maxMinisSunday: 8,
        maxMinisSaturday: 8,
        maxMinisHoliday: 10,
        familyProps: [] as {
          familyId: number,
          name: string,
          includeDates: { date: LocalDateTime, name: string }[],
          excludeDates: { date: LocalDateTime, name: string }[],
          assign?: boolean,
        }[],
        assignmentCoordinationTemplate: undefined as undefined | string,
        excludeUsers: [] as number[]
      },
      availableFamilies: [] as FamilyWithMembers[],
      messes: [] as { date: LocalDateTime, name: string }[],
      users: [] as Omit<User, "password">[],
      selectedFamily: "",
      selectedMess: "",
      templates: [] as AssignmentCoordinationTemplate[],
      createdPlanStatus: "Ready" as "Ready" | "Failed" | "Success" | "Creating",
      creating: false
    }
  },
  created() {
    this.fetchFamilies();
    this.fetchUsers()
    this.fetchTemplates();
  },
  methods: {
    async fetchTemplates() {
      this.templates = (await this.backend.query(assignmentCoordination.getAll, undefined))?.map((resp) => resp.template) ?? [];
    },
    async fetchUsers() {
      this.users = (await this.backend.query(users.getAll, undefined))?.sort((user, nextUser) => user.lastName.localeCompare(nextUser.lastName)) ?? [];
    },
    async createPlan() {
      const templateId = this.templates.find((template) => template.name === this.formData.assignmentCoordinationTemplate)?.id;
      if (templateId) {
        this.creating = true;
        this.createdPlanStatus = "Creating";
        const result = await this.backend.query(miniSchedule.createFromAssignmentTemplate, {
          maxMinisHoliday: Number(this.formData.maxMinisHoliday),
          minMinisHoliday: Number(this.formData.minMinisHoliday),
          maxMinisSaturday: Number(this.formData.maxMinisSaturday),
          minMinisSaturday: Number(this.formData.minMinisSaturday),
          maxMinisSunday: Number(this.formData.maxMinisSunday),
          minMinisSunday: Number(this.formData.minMinisSunday),
          familyProps: this.formData.familyProps.map((prop) => ({
            ...prop,
            includeDates: prop.includeDates.map((date) => date.date),
            excludeDates: prop.excludeDates.map((date) => date.date),
            assign: undefined
          })),
          assignmentCoordinationTemplateId: templateId,
          excludeUsers: this.formData.excludeUsers,
        });
        if (result?.success) {
          this.createdPlanStatus = "Success";
        } else {
          this.createdPlanStatus = "Failed";
        }
        this.creating = false;
      }
    },
    async fetchFamilies() {
      this.availableFamilies = (await this.backend.query(familyApi.getAll, undefined))?.sort((family, nextFamily) => family.name && nextFamily.name ? family.name.localeCompare(nextFamily.name) : 0) ?? [];
    },
    tryGetMessesForTemplate() {
      const selectedTemplate = this.templates.find((template) => template.name === this.formData.assignmentCoordinationTemplate);
      if (!selectedTemplate) {
        return;
      }
      this.messes = selectedTemplate.assignmentDates.flatMap((date) => date.times.map((time) => ({
        date: LocalDateTime.of(date.date, time.time),
        name: time.description ?? ""
      }))).sort((mess, nextMess) => mess.date.compareTo(nextMess.date));
    },
    formatDate(date: LocalDate) {
      return formatDate(date);
    },
    formatMess(mess: { date: LocalDateTime, name: string }) {
      return formatDate(mess.date.toLocalDate()) + " " + formatTime(mess.date.toLocalTime()) + " " + mess.name;
    },
    addMess(to: "include" | "exclude") {
      const family = this.availableFamilies.find((family) => family.name === this.selectedFamily);
      const dateTime = this.messes.find((mess) => this.formatMess(mess) === this.selectedMess);
      if (!family || !dateTime) {
        return;
      }
      const presentFamilyEntry = this.formData.familyProps.find((props) => props.familyId === family.id);
      if (presentFamilyEntry) {
        if (to === "include") {
          presentFamilyEntry.includeDates.push(dateTime);
        } else {
          presentFamilyEntry.excludeDates.push(dateTime);
        }
      } else {
        this.formData.familyProps.push({
          familyId: family.id,
          name: family.name ?? "Unknown name",
          includeDates: to === "include" ? [dateTime] : [],
          excludeDates: to === "exclude" ? [dateTime] : []
        })
      }
    },
    removeMess(from: "include" | "exclude", familyId: number, mess: { name: string, date: LocalDateTime }) {
      const familyData = this.formData.familyProps.find((prop) => prop.familyId === familyId);
      if (familyData) {
        if (from === "include") {
          familyData.includeDates = familyData.includeDates.filter((presentDateTime) => presentDateTime !== mess)
        } else {
          familyData.excludeDates = familyData.excludeDates.filter((presentDateTime) => presentDateTime !== mess)
        }
      }
    },
    getEntries(of: "include" | "exclude") {
      if (of === "include") {
        return this.formData.familyProps.flatMap((family) => family.includeDates.map((date) => ({
          familyId: family.familyId,
          name: family.name,
          mess: date
        })))
      }
      return this.formData.familyProps.flatMap((family) => family.excludeDates.map((date) => ({
        familyId: family.familyId,
        name: family.name,
        mess: date
      }))).sort((mess, nextMess) => mess.mess.date.compareTo(nextMess.mess.date))
    },
    getColorForStatus() {
      switch (this.createdPlanStatus) {
        case "Creating":
          return "yellow";
        case "Failed":
          return "red";
        case "Ready":
          return "gray";
        case "Success":
          return "green";
      }
    }
  }
})
